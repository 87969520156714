.validations {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.validations-form {
    display: flex;
    align-self: flex-start;
    width: 100%;
    margin-top: 1rem;
    justify-items: flex-start;
    justify-content: space-evenly;
    align-items: flex-end;
}

.filter-button {
    cursor: pointer;
    box-sizing: border-box;
    padding: 0.75rem 1.25rem;
    margin: 0;
    border: none;
    font-size: 0.875rem;
    font-weight: 700;
    outline: none;
    background-color: rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    transition: all 0.1s ease-in-out;
}

.filter-button:hover {
    background-color: rgba(34, 36, 38, 0.25);
}