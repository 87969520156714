.coupons {
    width: 80%;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
}

.coupons-form {
    display: flex;
    align-self: flex-start;
    width: 100%;
    margin-top: 1rem;
    justify-items: flex-start;
    justify-content: center;
    align-items: flex-end;
}

.coupons-form > * {
    margin: 0 1rem;
}