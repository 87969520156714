.select-menu {
    width: 200px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 1px solid silver;
    border-radius: 4px;
    color: rgba(191, 191, 191, 0.87);
    position: relative;
    font-size: 0.875rem;
}

.select-menu.opened {
    border: 1px solid rgba(33, 133, 208, 0.5);
    border-bottom-color: transparent;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select-menu-label {
    font-size: 0.8rem;
    font-weight: 600;
}

.select-menu-placeholder {
    position: relative;
}

.select-menu-placeholder::after {
    content: "";
    width: 10px;
    height: 10px;
    display: flex;
    position: absolute;
    border-left: 2px solid silver;
    border-bottom: 2px solid silver;
    transform: rotate(-45deg);
    margin-left: 9rem;
    top: 5%;
}

.select-menu-placeholder.highlight {
    color: #111;
}

.options-list {
    margin: 0;
    position: absolute;
    background-color: #FFF;
    width: 100%;
    left: -1px;
    top: 100%;
    color: #111;
    list-style: none;
    padding: 0;
    z-index: 2;
    border: 1px solid rgba(33, 133, 208, 0.5);
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.select-menu-item {
    padding: 12px 20px;
}

.select-menu-item:hover,
.select-menu-item.selected {
    background-color: rgba(0, 0, 0, 0.05);
}
