.validation-item {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 1rem 0;
}

.validation-item-header {
    font-size: 1.1rem;
    font-weight: 700;
}

.validation-item-info {
    font-size: 0.9rem;
}

.validation-item-info div {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 1rem;
}

.validation-item-info div::before {
    position: absolute;
    content: "\2022";
    font-size: 1.5rem;
    vertical-align: middle;
    top: -50%;
    left: -1rem;
    height: 100%;
}