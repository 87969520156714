.login-window {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: auto;
    grid-gap: 0.5rem;
}

.login-window h2 {
    text-align: center;
    color: #00B5AD;
}

.login-error-message {
    padding: 1rem;
    color: rgb(185, 0, 0);
    background-color: rgba(185, 0, 0, 0.05);
    font-weight: 700;
    border: 1px solid rgba(255, 0, 0, 0.25);
}

.login-form {
    width: 400px;
    display: grid;
    grid-gap: 0.5rem;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
}

.login-form input {
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 0.3rem;
    border: 1px solid rgba(34,36,38,.15);
}

.login-btn {
    background-color: #00B5AD;
    color: #FFF;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
}