.validation {
    margin: 0 auto;
    display: flex;
    width: 650px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.validation h1 {
    font-size: 1.8rem;
}

.validation-form {
    display: flex;
    align-items: center;
    width: 100%;
}

.validate-input {
    width: 550px;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    border: 1px solid silver;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.validate-button {
    cursor: pointer;
    box-sizing: border-box;
    padding: 0.75rem 1.25rem;
    margin: 0;
    background-color: rgb(33, 133, 208);
    color: #FFF;
    border: 1px solid transparent;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 1rem;
    font-weight: 700;
    outline: none;
}