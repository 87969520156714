.form-input {
    position: relative;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.8rem;
    font-weight: 600;
}

.form-input input {
    border-radius: 4px;
    font-size: 0.875rem;
}

.form-input:first-of-type {
    width: 270px;
}