/* .App {
    display: flex;
    justify-content: center;
} */

header {
    padding: 0 2rem;
    height: 75px;
    background-color: rgb(27, 28, 29);
    color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
}

.navigation {
    width: 50%;
    display: flex;
    justify-content: center;
}

.nav-link {
    margin: 0 1rem;
    padding: 0.5rem;
    font-size: 0.8rem;
    text-decoration: none;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.9);
}

.nav-link.active {
    border-bottom: 2px solid rgba(255, 255, 255, 0.9);;
}

.user-menu {
    width: 200px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.user-menu span {
    font-size: 0.8rem;
    font-weight: 700;
}

.user-menu button {
    width: 100px;
    background-color: rgb(27, 28, 29);
    border: 2px solid #FFF;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 700;
    color: #FFF;
    padding: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.user-menu button:hover {
    background-color: rgba(255, 255, 255, 0.9);
    color: rgb(27, 28, 29);
}

/* .ticket-check {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
}

.ticket-check input {
    padding: 0.25rem 0.5rem;
    width: 200px;
    border: 1px solid silver;
    border-right-color: transparent;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 1rem;
}

.submit-btn {
    cursor: pointer;
    background-color: rgb(33, 133, 208);;
    color: white;
    font-size: 1rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 0.25rem;
    border: none;
    border-left-color: transparent;
} */