body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

input {
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    border: 1px solid silver;
}

input:focus {
    outline: none;
    border: 1px solid rgba(33, 133, 208, 0.6);
}

input::placeholder {
    color: rgba(191, 191, 191, 0.87);
}

input:focus::placeholder {
    color: rgb(155, 153, 153);
}