.loader {
    margin: 0 auto;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #00B5AD;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: spin 1.5s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}